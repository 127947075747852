<template>
  <div class="block-fs">

    <div class="block-fs__head">

      <h1 class="page-title" v-html="title"></h1>
      <p class="page-text" v-html="text"></p>

    </div>

    <div class="block-fs__body">
      <div class="block-fs__form">

        <RadioButton
          v-for="item in typeList"
          :key="item.value"
          :name="item.name"
          :image="item.image"
          :value="item.value"
          v-on="$listeners"
        ></RadioButton>

      </div>

      <button
        class="btn btn--prime btn--fs"
        :disabled="disableButton"
        @click="$emit('next')"
      >Далее</button>

    </div>
  </div>
</template>

<script>
import RadioButton from "@component/Module/AssistanceSelect/RadioButton";

export default {
  name: "TypeSelect",
  components: {
    RadioButton
  },
  props: {
    typeList: {
      type: Array,
      required: true
    },
    title: String,
    text: String,
    disableButton: Boolean
  }
};
</script>

<style scoped>

</style>
