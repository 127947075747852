<template>
  <div class="block-fs">
    <div class="block-fs__head">
      <h1 class="page-title">Выбор страны</h1>
      <p class="page-text">Желаемый район, город, регион, где планируете выполнять услуги.</p>
    </div>

    <div class="block-fs__body">
      <div class="block-fs__form">
        <v-form v-model="valid">
          <Select label="Страна" item-text="name" item-value="id" :items="countries" v-model="formData.country" :required="true" />
          <Select
            color="#0C76DA"
            :loading="loading.region"
            label="Регион"
            item-text="name"
            item-value="id"
            :items="regions"
            v-model="formData.region"
            :required="true"
          />
          <Select
            color="#0C76DA"
            :loading="loading.district"
            item-text="name"
            item-value="id"
            label="Район"
            :items="areas"
            v-model="formData.area"
            :required="true"
          />
          <Select
            color="#0C76DA"
            :loading="loading.city"
            item-text="name"
            item-value="id"
            label="Город"
            :items="cities"
            v-model="formData.city"
            :required="true"
          />
        </v-form>
      </div>

      <button class="btn btn--prime btn--fs" @click="$emit('next')" :disabled="!valid">Далее</button>

      <button class="btn btn--border btn--fs" @click="$emit('back')">Назад</button>
    </div>
  </div>
</template>

<script>
import Select from '@component/UI/Form/Select.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AssistanceLocationSelect',
  components: {
    Select,
  },
  data() {
    return {
      loading: {
        region: false,
        district: false,
        city: false,
      },
      valid: false,
      regions: [],
      areas: [],
      cities: [],
      formData: {
        type: null,
        country: null,
        city: null,
        area: null,
        region: null,
      },
    };
  },
  computed: {
    ...mapGetters('enums', ['countries']),
  },
  watch: {
    'formData.country': async function (countryId) {
      this.loading.region = true;
      this.regions = await RequestManager.Enums.getRegions(countryId);
      this.loading.region = false;
    },
    'formData.region': async function () {
      this.loading.district = true;
      const { country, region } = this.formData;
      this.areas = await RequestManager.Enums.getAreas(country, region);
      this.loading.district = false;
    },
    'formData.area': async function () {
      this.loading.city = true;
      const { country, region, area } = this.formData;
      this.cities = await RequestManager.Enums.getCities(country, region, area);
      this.loading.city = false;
    },
    'formData.city'() {
      this.$emit('input', this.formData);
    },
  },
};
</script>
