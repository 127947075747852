<template>
  <div class="page-content page-content--flex fs-content--increased">

    <div class="fs-content--shadow">
      <TypeSelect
        v-if="activeBlock === 'type'"
        v-model="type"
        :type-list="typeList"
        :disable-button="!type"
        title="Мне нужна помощь"
        text="Выберите один из двух вариантов.<br/>Для кого необходима помощь?"
        @next="activeBlock = 'location'"
      />

      <LocationSelect
        v-else
        v-model="location"
        @next="next"
        @back="activeBlock = 'type'"
      />
    </div>
  </div>
</template>

<script>

import TypeSelect from "@component/Module/AssistanceSelect/TypeSelect";
import LocationSelect from "@component/Module/AssistanceSelect/LocationSelect";

export default {
  name: "AssistanceNeedSelect",
  components: {
    TypeSelect,
    LocationSelect
  },
  data() {
    return {
      location: {},
      type: null,
      activeBlock: 'type',
      typeList: [
        {
          name: 'Для родственников',
          value: 'relative',
          image: require('@img/icons/app-family.svg')
        },
        {
          name: 'Для себя',
          value: 'yourself ',
          image: require('@img/icons/app-myself.svg')
        },
      ]
    };
  },
  methods: {
    next() {
      this.$router.push({
        name: this.$routeName.REGISTRATION_CUSTOMER,
        query: {
          ...this.location,
          type: this.type,
        }
      });

    },
  },
};
</script>

<style lang="scss" scoped>

// block-fs
::v-deep {
  .block-fs {
    width: 100%;
    max-width: 500px;

    .block-fs__head {
      padding: 0 20px;
      margin-bottom: 24px;
      @include respond-to('sm') {
        text-align: center;
      }

      .page-text {
        max-width: 343px;
        margin: 0 auto;
      }
    }

    .block-fs__body {
      max-width: 343px;
      margin: 0 auto;
    }

    .block-fs__form {
      margin-bottom: 20px;
    }

    .btn {
      margin-bottom: 10px;
    }
  }
}
</style>
