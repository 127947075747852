<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :label="label"
    :items="items"
    :rules="rules"
    :value="value"
    :menu-props="{ bottom: true, offsetY: true }"
  />
</template>

<script>
export default {
  name: 'Select',
  props: {
    label: String,
    value: [Number, String],
    required: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    rules() {
      const rules = [];

      if (this.required) {
        const rule = value => !!value || 'Обязательное поле';
        rules.push(rule);
      }

      return rules;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep{
  .v-label{
    color: red;
  }
}

</style>
