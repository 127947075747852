<template>
  <label class="cd-radio">

    <input
      type="radio"
      name="type"
      :value="value"
      @change="e => $emit('input', e.target.value)"
      class="cd-radio__field"
    />

    <div class="cd-radio__vue">

      <div class="cd-radio__item">
        <div class="cd-radio__box"></div>
        <div class="cd-radio__text">{{ name }}</div>
      </div>

      <div class="cd-radio__icon">
        <img class="cd-radio__img" :src="image" alt="icon">
      </div>

    </div>

  </label>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    name: String,
    value: String,
    image: String,
  }
};
</script>
